// Angular
import { Directive, HostListener } from '@angular/core';

/**
 * Listen Input focus
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[selectOnFocusEvent]',
    standalone: false
})
export class SelectOnFocusEventDirective {

  /**
   * Directive Constructor
   */
  constructor() {
  }

  /**
   * A directive handler the input focus event for selectall text
   * param target
   */
  @HostListener('focus', ['$event.target'])
  onFocus(target: any) {
    target.select();
  }
}
