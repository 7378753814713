// Angular
import { Pipe, PipeTransform } from "@angular/core";
// Environment
import { environment } from "@environment";
// Lodash
import { replace } from "lodash";

/**
 * Returns Seller Own Stock URL
 */
@Pipe({
    name: "OwnStockUrl",
    standalone: false
})
export class OwnStockURLPipe implements PipeTransform {

  /**
   * Returns Seller Own Stock URL
   * @param {string} companyId
   * @returns {string}
   * @memberof OwnStockLURLPipe
   */
  transform(companyId: number): string {
    const ownStockLink: string = environment.ownStockUrl;
    return replace(ownStockLink, 'x', String(companyId));
  }
}