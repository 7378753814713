// Angular
import { Inject, Injectable, inject, provideAppInitializer } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
// RxJS & Lodash
import { catchError, tap } from 'rxjs/operators';
import { of, Observable, ReplaySubject } from 'rxjs';
import { toLower, split, get } from 'lodash';
// Environment
import { environment } from '@environment';
// Constants
import { API_URL } from '@constants';
const SYSTEM_TEST = 'systemtest';


@Injectable({
    providedIn: 'root',
})
export class AppInitLoaderService {
    // Public
    company: any;
    domainWithoutCid: string;

    /**
     * Workaround for the problem where Guards and Resolvers don't wait for APP_INITIALIZER
     * if RouterModule initialNavigation: 'enabledBlocking' is set.
     * 'enabledBlocking' is required for server-side rendering to work.
     */
    appInitDoneSubject = new ReplaySubject<boolean>(1);

    /**
     * Creates an instance of AppInitLoaderService.
     * @param {HttpClient} http
     * @param {Document} document
     * @memberof AppInitLoaderService
     */
    constructor(
        private http: HttpClient,
        @Inject(DOCUMENT) private document: Document
    ) { }

    /**
     * Get company data from the server based on the URL
     * @return {*}  {Observable<any>}
     * @memberof AppInitLoaderService
     */
    public init(): Observable<any> {
        try {
            const siteDomain = toLower(this.document.location.host);
            const host = split(siteDomain, '.');
            const ownStockHost = split(host[0], '-');

            // Check if the domain starts with 'cid-' and has a company ID
            if (ownStockHost[0] !== 'cid' || !ownStockHost[1]) {
                return this.returnEmpty();
            }

            const ownStockId = ownStockHost[1];

            // Extract the application name from the second-to-last part of the domain
            if (host.length < 2) {
                return this.returnEmpty();
            }

            // Determine application name (use system test or second-last domain part)
            const applicationName = ownStockHost.length > 2 && ownStockHost[2] === SYSTEM_TEST ? SYSTEM_TEST : host[host.length - 2];

            // Remove 'cid-xxx-' prefix from the domain
            const cleanedDomain = this.cleanDomain(siteDomain);

            this.domainWithoutCid = `https://${cleanedDomain}`;

            // Send request to fetch company data
            return this.http
                .get(`${environment.apiBaseUrl}${API_URL.AFFILIATED_COMPANIY_URL}`, {
                    headers: this.getHeaders(applicationName, ownStockId),
                    params: { id: ownStockId }
                })
                .pipe(
                    tap((data) => this.handleCompanyData(data, ownStockId, applicationName)),
                    catchError((error) => {
                        console.error("Error fetching company data:", error);
                        return this.returnEmpty();
                    })
                );
        } catch (error) {
            console.error("Error in app initialization:", error);
            return this.returnEmpty();
        }
    }

    /**
      * Clean the domain by removing 'cid-xxx-' prefix
      * @private
      * @param {string} domain
      * @return {string} cleanedDomain
      */
    private cleanDomain(domain: string): string {
        let cleanedDomain = domain.replace(/^cid-\d+-?/, '');
        return cleanedDomain.startsWith('.') ? cleanedDomain.slice(1) : cleanedDomain;
    }

    /**
     * Create HTTP headers for the request
     * @private
     * @param {string} applicationName
     * @param {string} ownStockId
     * @return {HttpHeaders}
     */
    private getHeaders(applicationName: string, ownStockId: string): HttpHeaders {
        return new HttpHeaders({
            accept: 'application/json',
            'x-application-name': applicationName,
            'x-application-company-id': ownStockId,
        });
    }

    /**
     * Handle the company data after receiving the response
     * @private
     * @param {*} data
     * @param {string} ownStockId
     * @param {string} applicationName
     */
    private handleCompanyData(data: any, ownStockId: string, applicationName: string): void {
        if (get(data, 'success') && get(data, 'data', []).length > 0) {
            this.company = { ...get(data, 'data[0]'), ownStockId, applicationName };
        }
        this.appInitDoneSubject.next(true);
    }

    /**
     * Return empty data in case of failure or missing conditions
     * @private
     * @returns {Observable<any>}
     * @memberof AppInitLoaderService
     */
    private returnEmpty(): Observable<any> {
        this.appInitDoneSubject.next(true);
        return of([]);
    }

}

/**
 * APP Initialize Factory Service
 * @param {AppInitLoaderService} appLoader
 * @return {*}
 */
function AppInitFactoryService(appLoader: AppInitLoaderService) {
    return () => appLoader.init();
}

/** APP INIT PROVIDER */
export const APP_INIT_PROVIDER = provideAppInitializer(() => {
        const initializerFn = (AppInitFactoryService)(inject(AppInitLoaderService));
        return initializerFn();
      });