// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { makeStateKey, TransferState } from '@angular/core';
// RxJS & Lodash
import { get, find, join, split, trim } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil, catchError, take } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthService, Login } from '../../../../core/auth';
import { CommonService, GlobalErrorHandler } from '@core/utils';
// Model
import { IUserAccount, IUserLogin } from 'src/app/core/auth/_models/user.model';
// Material
import { MatSnackBar } from '@angular/material/snack-bar';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Services
import { LayoutService } from '../../layout/services';
// Constants
import { LOCALES, PART_LANG_HREF_REDIRECTS_PATHS } from '@constants';

// State key
const STATE_KEY_HREF = makeStateKey('hrefData');

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit, OnDestroy {

	// Public params
	loginForm: FormGroup;
	loading = false;
	errors: any = [];
	companies = [];
	languageChange$: Observable<string>;

	// Private declarations
	private unsubscribe = new Subject<void>();
	private returnUrl: string | undefined;

	/**
	 * Creates an instance of LoginComponent.
	 * @param {Router} router
	 * @param {AuthService} auth
	 * @param {Store<AppState>} store
	 * @param {FormBuilder} fb
	 * @param {ChangeDetectorRef} cdr
	 * @param {ActivatedRoute} route
	 * @param {GlobalErrorHandler} globalErrorHandler
	 * @param {MatSnackBar} snackBar
	 * @param {TranslocoService} translocoService
	 * @param {TransferState} transferState
	 * @param {LayoutService} layoutService
	 * @param {CommonService} cs
	 * @memberof LoginComponent
	 */
	constructor(
		private readonly router: Router,
		private readonly auth: AuthService,
		private readonly store: Store<AppState>,
		private readonly fb: FormBuilder,
		private readonly cdr: ChangeDetectorRef,
		private readonly route: ActivatedRoute,
		private readonly globalErrorHandler: GlobalErrorHandler,
		private readonly snackBar: MatSnackBar,
		private readonly translocoService: TranslocoService,
		private readonly transferState: TransferState,
		private readonly layoutService: LayoutService,
		private readonly cs: CommonService,
	) {

	}

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Init login form
		this.initLoginForm();

		// Get Current live language
		this.languageChange$ = this.translocoService.langChanges$;


		// Redirect back to the returnUrl before login
		this.route.queryParams.pipe(
			takeUntil(this.unsubscribe)
		).subscribe(params => {
			this.returnUrl = params.returnUrl;
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		this.transferState.remove(STATE_KEY_HREF);
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
		const username = trim(controls.email.value).toLowerCase();
		const loginData: IUserLogin = {
			username,
			password: controls.password.value,
			company_id: controls.companyid.value,
			company_default: null
		};
		this.auth.login(loginData).pipe(
			take(1),
			catchError(error => this.globalErrorHandler.handleError(`login-form-submit | ${loginData.username}`, error)),
			takeUntil(this.unsubscribe)
		).subscribe((response) => this.handleLoginResponse(response));
	}

	/**
	 * Checking control validation
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	private initLoginForm() {
		this.loginForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
			])
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
			companyid: [null]
		});
	}

	/**
	 * Handle Login Response
	 * @param response: any
	 * @returns void
	 */
	private handleLoginResponse(response: any): void {
		if (response && response.success) {
			// Set User into Store
			this.store.dispatch(new Login({ user: response?.data, authToken: response?.data?.token?.legacy }));
			// Set User Environment
			this.setUserEnvironmentSettings(response?.data);
			// Redirect logic after login
			this.redirectLogicOnLogin(response?.data);
		} else {
			this.snackBar.open(this.translocoService.translate('sentence_login_details_is_incorrect'), this.translocoService.translate('label_close'), {
				panelClass: ['danger-dialog']
			});
			this.loading = false;
		}
		this.cdr.markForCheck();
	}

	/**
	 * Set Logged In User Environment Settings
	 * @private
	 * @param {*} userInfo
	 */
	private setUserEnvironmentSettings(userInfo: IUserAccount) {
		// Set customer type
		this.layoutService.setCustomerType((get(userInfo, 'address.context.user_type', 1)).toString());

		// Set customer country
		const customerCountry = this.cs.getUserLocale(get(userInfo, 'environment.country_id', ''));
		this.layoutService.setCustomerCountry(customerCountry);

		// Set customer currency
		this.layoutService.setCustomerCurrency(get(userInfo, 'environment.currency', 'SEK'));

		// Set cusomer language
		const customerLanguage = find(LOCALES, (l) => l.id == get(userInfo, 'environment.language', 752));
		this.layoutService.setCustomerLanguage(customerLanguage);
	}

	/**
	 * Redirect user to there locale prefred URL after they login
	 * @description We must set hrefLang into transferState so we have access to locale prefred url's
	 * @protected
	 * @param {*} response
	 * @memberof LoginComponent
	 */
	protected redirectLogicOnLogin(response: IUserAccount) {
		// Only redirect for part-search routes
		const isPartSearchPaths = PART_LANG_HREF_REDIRECTS_PATHS.some(path => this.returnUrl?.includes(path));
		const result = this.transferState.get<any>(STATE_KEY_HREF, null);
		if (isPartSearchPaths && null !== result) {
			const userLocale = get(response, 'environment.language', '840');
			this.router.navigateByUrl(result[userLocale] ? result[userLocale][2] : this.translocoService.getActiveLang())
		} else {
			const language = this.layoutService.getCustomerLanguage();
			const redirectLink = split(this.returnUrl, '/');
			redirectLink[1] = language;
			this.returnUrl = join(redirectLink, '/');
			this.router.navigateByUrl(this.returnUrl); // Main page
		}
	}
}
