// Angular
import { registerLocaleData } from '@angular/common';
// Import all desired locale files
import localeEn from '@angular/common/locales/en';
import localeSv from '@angular/common/locales/sv';
import localeNb from '@angular/common/locales/nb';
import localeDa from '@angular/common/locales/da';
import localeIs from '@angular/common/locales/is';
import localeFi from '@angular/common/locales/fi';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';

// Register all locale data
registerLocaleData(localeEn);
registerLocaleData(localeDa);
registerLocaleData(localeSv);
registerLocaleData(localeNb);
registerLocaleData(localeIs);
registerLocaleData(localeFi);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);

// Constants
import { APP_CONSTANT } from '@constants';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// RxJS & Lodash
import { indexOf, split, trim } from 'lodash';
import { catchError, take } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';

// Load I18n locale before loading application
export function preloadI18nLocale(transloco: TranslocoService, document: Document): () => Promise<void> {
    return async () => {
        try {
            const availableLangs: any = transloco.getAvailableLangs();
            const splitURLLang = trim(split(document.location.pathname, '/')[1]);
            let localeId = splitURLLang && splitURLLang !== '' ? splitURLLang : APP_CONSTANT.DFAULT_LOCALE;

            if (indexOf(availableLangs, localeId) === -1) {
                localeId = APP_CONSTANT.DFAULT_LOCALE;
            }

            // Use firstValueFrom to replace toPromise
            await firstValueFrom(
                transloco.load(localeId).pipe(
                    take(1),
                    catchError((err) => {
                        console.error('Error while loading preloadI18nLocale: ', err);
                        return of(null); // Graceful completion
                    })
                )
            );

            transloco.setActiveLang(localeId);
        } catch (error) {
            console.error('Exception in preloadI18nLocale', error);
            // Ensure the function always resolves
            return Promise.resolve();
        }
    };
}