// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Constants
import { COUNTRIES } from '@constants';

/**
 * Returns country name from country-id
 */
@Pipe({
    name: 'countryName',
    standalone: false
})
export class CountryNamePipe implements PipeTransform {

  /**
   * Transform
   * @param value: any
   * @param type: any
   */
  transform(countryId: any, type?: string) {
    if (null === countryId && countryId === '') return 'NA';
    const details = COUNTRIES[countryId as keyof typeof COUNTRIES];
    if (!details) return countryId;
    switch (type) {
      case 'country':
        return details.country;
      case 'language':
        return details.language;
      default:
        return 'NA';
    }
  }
}
