// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Lodash
import { isUndefined } from 'lodash';

@Pipe({
    name: 'backfrompretty',
    standalone: false
})
export class BackFromPrettyPipe implements PipeTransform {

  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    let back = value;
    try {
      if (isUndefined(value) || null === value) return '';
      back = back.replace(/\-/g, ' ');
      back = back.replace(/\_/g, '-');
      back = back.replace(/\&/g, '/');
    } catch (error) {
      back = value;
    }
    return back;
  }
}
