// Angular
import { Pipe, PipeTransform } from "@angular/core";
// Lodash
import { get } from "lodash";

/**
 * Returns Product Details URL
 */
@Pipe({
    name: "PdpUrl",
    standalone: false
})
export class PDPLURLPipe implements PipeTransform {

  /**
   * Return Produt Details Page Link
   * @param {*} route
   * @returns {string}
   * @memberof PDPLURLPipe
   */
  transform(route: any, item?: any): string {
    switch (get(item, 'code.part.code_pc.code')) {
      case 1: // Dismantled object
        return `/${route?.lang}/vs/${route?.search}/${route?.type}/s10/${route?.vc1 ?? '_'}/${route?.vc ?? '_'}/${route?.vcx ?? '_'}/ID-${get(item, 'vehicle.vpost_id')}`;

      default:
        if (route?.tyre_width) {
          return `/${route?.lang}/df/${route?.search}/${route?.type}/${route?.tyre_width}-${route?.tyre_aspect_ratio}-${route?.tyre_wheel_diameter_inches}/${route?.id}`;
        } else {
          return `/${route?.lang}/pb/${route?.search}/${route?.type}/s1/${route?.vc1 ?? '_'}/${route?.vc ?? '_'}/${route?.vcx ?? '_'}/${route?.pc1 ?? '_'}/${route?.pc ?? '_'}/${route?.pos ?? '_'}/${route?.id}/${route?.oem ? encodeURIComponent(route?.oem) : ''}`;
        }
    }
  }
}