// Angular
import { Injectable, inject, signal, computed } from '@angular/core';
// Constants
import { CART_PART_LS_KEY } from '@constants';
// Service
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    private commonService = inject(CommonService);

    /**
     * Signal to track items in the cart as a Set of part IDs.
     * This signal reflects the current state of the cart.
     */
    public cartItemsSignal = signal<Set<number>>(this.getInitialCartParts());

    /**
     * Signal to track the count of items in the cart.
     * Computed from `cartItemsSignal` to dynamically update the count.
     */
    public cartCountSignal = computed(() => this.cartItemsSignal().size);

    /**
     * Retrieves the initial cart parts from local storage and initializes the cart.
     * @returns {Set<number>} A Set containing the part IDs stored in local storage.
     */
    private getInitialCartParts(): Set<number> {
        const cartParts = this.commonService.getFromLocalStorage(CART_PART_LS_KEY);
        return new Set(cartParts ? cartParts : []);
    }

    /**
     * Updates the cart parts by merging the new data with the current data in the cart.
     * Saves the updated state to local storage and updates the signal.
     * @param {Set<number>} newData - A Set of new part IDs to update the cart.
     */
    updateCartParts(newData: Set<number>): void {
        const localStorageParts = new Set<number>(
            this.commonService.getFromLocalStorage(CART_PART_LS_KEY) || []
        );
        const updatedParts = new Set([...localStorageParts, ...newData]);

        this.cartItemsSignal.set(updatedParts); // Update signal
        this.commonService.setIntoLocalStorage(CART_PART_LS_KEY, Array.from(updatedParts)); // Save to local storage
    }

    /**
     * Clears all parts from the cart.
     * Updates the signal to an empty Set and removes the cart data from local storage.
     */
    removeCartPart(): void {
        this.cartItemsSignal.set(new Set([])); // Update signal with an empty Set
        this.commonService.removeFromLocalStorage(CART_PART_LS_KEY); // Remove cart data from local storage
    }

    /**
     * Adds a new part to the cart.
     * Merges the new part with the existing cart data in the signal and local storage.
     * @param {number} part - The ID of the part to add to the cart.
     */
    addToCart(part: number): void {
        this.cartItemsSignal.update((parts) => {
            const localStorageParts = new Set<number>(
                this.commonService.getFromLocalStorage(CART_PART_LS_KEY) || []
            );
            const updatedParts = new Set([...localStorageParts, ...parts]);
            updatedParts.add(part); // Add the new part

            this.commonService.setIntoLocalStorage(CART_PART_LS_KEY, Array.from(updatedParts)); // Save to local storage

            return updatedParts; // Update signal with new data
        });
    }

    /**
     * Removes a part from the cart.
     * Updates the signal and local storage by removing the specified part ID.
     * @param {number} partId - The ID of the part to remove from the cart.
     */
    removeFromCart(partId: number): void {
        this.cartItemsSignal.update((parts) => {
            const localStorageParts = new Set<number>(
                this.commonService.getFromLocalStorage(CART_PART_LS_KEY) || []
            );
            const updatedParts = new Set([...localStorageParts, ...parts]);
            updatedParts.delete(partId); // Remove the part

            this.commonService.setIntoLocalStorage(CART_PART_LS_KEY, Array.from(updatedParts)); // Save to local storage

            return updatedParts; // Update signal with new data
        });
    }
}
