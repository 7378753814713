// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// RxJS & Lodash
import { catchError } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { get, trim } from 'lodash';
// Auth
import { AuthService } from '../../../../core/auth';
import { GlobalErrorHandler } from '@core/utils';
// Material
import { MatSnackBar } from '@angular/material/snack-bar';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Store
import { Store } from '@ngrx/store';
import { Logout } from 'src/app/core/auth';
import { AppState } from 'src/app/core/reducers';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: false
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

	// Public Variables
	changePasswordForm: FormGroup;
	loadingAfterSubmit: boolean;
	hidePassword = true;
	languageChange$: Observable<string>;

	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Creates an instance of ResetPasswordComponent.
	 * @param {AuthService} authService
	 * @param {Router} router
	 * @param {ActivatedRoute} route
	 * @param {FormBuilder} fb
	 * @param {ChangeDetectorRef} cdr
	 * @param {GlobalErrorHandler} globalErrorHandler
	 * @param {MatSnackBar} snackBar
	 * @param {TranslocoService} translocoService
	 * @param {Store<AppState>} store
	 * @memberof ResetPasswordComponent
	 */
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly fb: FormBuilder,
		private readonly cdr: ChangeDetectorRef,
		private readonly globalErrorHandler: GlobalErrorHandler,
		private readonly snackBar: MatSnackBar,
		private readonly translocoService: TranslocoService,
		private readonly store: Store<AppState>
	) {
		// Get Current live language
		this.languageChange$ = this.translocoService.langChanges$;
	}

	/**
	 * On init
	 */
	ngOnInit() {
		this.createForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * On submit change password
	 */
	onSubmit() {
		const controls = this.changePasswordForm.controls;
		/** check form */
		if (this.changePasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loadingAfterSubmit = true;
		const resetPwdToken = this.route.snapshot.paramMap.get('token');
		const password = controls.password.value;
		const payload = {
			token: trim(resetPwdToken as string),
			pwd_compare: password,
			pwd: password
		};
		const changePasswordSubscription = this.authService.changePassword(payload).pipe(
			catchError(error => this.globalErrorHandler.handleError('change-password-sumbit', error)),
		).subscribe((response: any) => {
			if (response && response.success) {
				this.store.dispatch(new Logout());
				this.snackBar.open(this.translocoService.translate('sentence_password_changed_successfully'), this.translocoService.translate('label_close'), {
					panelClass: ['success-dialog']
				});
				this.router.navigateByUrl(`/${this.translocoService.getActiveLang()}/auth/login`);
			} else {
				const message = response.status === 400 ? 'Reset Password Token expired' : get(response, 'message', this.translocoService.translate('sentence_something_went_wrong'));
				this.snackBar.open(message, this.translocoService.translate('label_close'), {
					panelClass: ['danger-dialog']
				});
			}
			this.loadingAfterSubmit = false;
			this.cdr.detectChanges();
		});
		this.subscriptions.push(changePasswordSubscription);
	}

	/**
	 * Init form
	 */
	private createForm() {
		this.changePasswordForm = this.fb.group({
			password: ['', [Validators.required, Validators.minLength(8)]],
			confirmPassword: ['', Validators.required]
		}, {
			validators: this.confirmedPwdValidator('password', 'confirmPassword')
		});
	}

	/**
	 * Confirmed Password Validator
	 * @param password: string
	 * @param confirmPwd: string
	 * @returns ValidatorFn
	 */
	private confirmedPwdValidator(password: string, confirmPwd: string): ValidatorFn {
		return (formGroup: AbstractControl): { [key: string]: any } | null => {
			const control: any = formGroup.get(password);
			const matchingControl: any = formGroup.get(confirmPwd);
			if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
				return null;
			}
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({ confirmedValidator: true });
				return null;
			} else {
				matchingControl.setErrors(null);
				return null;
			}
		};
	}

}
