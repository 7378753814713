// Angular
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// Model
import { IUserLocale } from '@shared/models';
// RxJs & Lodash
import { toString, trim } from 'lodash';
// Constant
import { APP_CONSTANT } from '@constants';
// Environment
import { environment } from '@environment';

@Injectable({ providedIn: 'root' })
export class CommonService {

    /**
     * Creates an instance of CommonService.
     * @param {Object} platformId
     * @memberof CommonService
     */
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }

    /**
     * SET LOCAL STOARGE SERVICES
     * @param key: string
     * @param value: any
     * @returns void
     */
    setIntoLocalStorage(key: string, value: any): void {
        if (isPlatformBrowser(this.platformId)) {
            let globalSharedData = localStorage.too_perfect_global_shared_data;
            if (globalSharedData && null != globalSharedData) {
                globalSharedData = JSON.parse(globalSharedData);
                globalSharedData[key] = value;
            }
            else {
                globalSharedData = {};
                globalSharedData[key] = value;
            }
            localStorage.too_perfect_global_shared_data = JSON.stringify(globalSharedData);
        }
    }

    /**
     * REMOVE SHARED OBJECT INSTANCE FROM LOCAL STOARGE
     * @param key: string
     * @returns any
     */
    removeFromLocalStorage(key: string): any {
        if (isPlatformBrowser(this.platformId)) {
            let globalSharedData = localStorage.too_perfect_global_shared_data;
            if (globalSharedData && null != globalSharedData) {
                globalSharedData = JSON.parse(globalSharedData);
                delete globalSharedData[key];
                localStorage.too_perfect_global_shared_data = JSON.stringify(globalSharedData);
                return true;
            }
            return false;
        }
    }

    /**
     * GET SHARED OBJECT INSTANCE FROM LOCAL STOARGE
     * @param key: string
     * @returns any
     */
    getFromLocalStorage(key: string): any {
        if (isPlatformBrowser(this.platformId)) {
            let globalSharedData = localStorage.too_perfect_global_shared_data;
            let value;
            if (globalSharedData && null != globalSharedData) {
                globalSharedData = JSON.parse(globalSharedData);
                value = globalSharedData[key];
            }
            return value;
        } else {
            return null;
        }
    }

    /**
     * SET SESSION STORAGE SERVICES
     * @param sessionkey: any
     * @param inputSessionObj: any
     * @returns void
     */
    setInSessionStorage(sessionkey: any, inputSessionObj: any): void {
        try {
            if (isPlatformBrowser(this.platformId)) {
                const sessionObjString = sessionStorage.getItem(sessionkey);
                let sessionObj: any = {};
                if (sessionObjString != null && trim(sessionObjString) !== '') {
                    sessionObj = JSON.parse(sessionObjString);
                }
                // eslint-disable-next-line guard-for-in
                for (const key in inputSessionObj) {
                    sessionObj[key] = inputSessionObj[key];
                }
                sessionStorage.setItem(sessionkey, JSON.stringify(sessionObj));
            }
        } catch (ex) {
            console.log('Exception in setInSessionStorage', ex);
        }

    }

    /**
     * GET FROM SESSION STORAGE SERVICES
     * @param {string} key
     * @param {(string | number)} [subKey]
     * @returns {*}
     * @memberof CommonService
     */
    getFromSessionStorage(key: string, subKey?: string | number): any {
        try {
            if (isPlatformBrowser(this.platformId)) {
                let sessionObj: any = sessionStorage.getItem(key);
                let sessionValue = null;
                if (sessionObj != null && trim(sessionObj) !== '') {
                    sessionObj = JSON.parse(sessionObj);
                    if (subKey) {
                        if (sessionObj[subKey] != null) {
                            sessionValue = sessionObj[subKey];
                        }
                    } else {
                        sessionValue = sessionObj;
                    }
                }
                return sessionValue;
            } else {
                return null;
            }
        } catch (ex) {
            console.log('Exception in getFromSessionStorage', ex);
        }

    }

    /**
     * REMOVE FROM SESSION STORAGE SERVICES
     * @param key: any
     * @param subKey: any
     * @returns void
     */
    removeFromSessionStorage(key: string, subKey?: string | number): void {
        try {
            if (isPlatformBrowser(this.platformId)) {
                const sessionObj = sessionStorage.getItem(key);
                if (sessionObj !== null) {
                    if (subKey) {
                        const sessionObject = JSON.parse(sessionObj);
                        if (sessionObject.hasOwnProperty(subKey)) {
                            delete sessionObject[subKey];
                            sessionStorage.setItem(key, JSON.stringify(sessionObject));
                        }
                    } else {
                        sessionStorage.removeItem(key);
                    }
                }
            }
        } catch (ex) {
            console.log('Exception in removeFromSessionStorage', ex);
        }
    }

    /**
     * CLEAR ALL USER REALEATED STOARGE , SESSION SERVICES
     * @returns void
     */
    clearAll(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.removeFromLocalStorage(APP_CONSTANT.LOGGED_USER);
            this.removeFromLocalStorage(APP_CONSTANT.JWT_TOKEN_KEY);
            sessionStorage.clear();
        }
    }

    /**
     * Returns user locale
     * @param langId: string
     * @returns IUserLocale
     */
    getUserLocale(locale: string): IUserLocale {
        const _locale = toString(locale);
        switch (_locale) {
            case 'sv-se': // Swedish
            case 'SE':
            case '752':
                return { id: '752', locale: 'sv-se', code: 'SE', name: 'sweden', flag: 'sweden', utf: 'sv_SE.UTF8', locale_code: 'sv_SE' };
            case 'fi-fi': // Finnish
            case 'FI':
            case '246':
                return { id: '246', locale: 'fi-fi', code: 'FI', name: 'finland', flag: 'finland', utf: 'fi_FI.UTF8', locale_code: 'fi_FI' };
            case 'da-dk': // Danish
            case 'DK':
            case '208':
                return { id: '208', locale: 'da-dk', code: 'DK', name: 'denmark', flag: 'denmark', utf: 'da_DK.UTF8', locale_code: 'da_DK' };
            case 'de-de': // German
            case 'DE':
            case '276':
                return { id: '276', locale: 'de-de', code: 'DE', name: 'germany', flag: 'germany', utf: 'de_DE.UTF8', locale_code: 'de_DE' };
            case 'nb-no': // Norwegian
            case 'NO':
            case '578':
                return { id: '578', locale: 'nb-no', code: 'NO', name: 'norway', flag: 'norway', utf: 'no_NO.UTF8', locale_code: 'no_NO' };
            case 'es-es': // Spanish
            case 'ES':
            case '724':
                return { id: '724', locale: 'es-es', code: 'ES', name: 'spain', flag: 'spain', utf: 'es_ES.UTF8', locale_code: 'es_ES' };
            case 'is-is': // Icelandic
            case 'IS':
            case '352':
                return { id: '352', locale: 'is-is', code: 'IS', name: 'iceland', flag: 'iceland', utf: 'is_IS.UTF8', locale_code: 'is_IS' };
            case 'fr-fr': // French
            case 'FR':
            case '250':
                return { id: '250', locale: 'fr-fr', code: 'FR', name: 'france', flag: 'france', utf: 'fr_FR.UTF8', locale_code: 'fr_FR' };
            case 'it-it': // Italian
            case 'IT':
            case '380':
                return { id: '380', locale: 'it-it', code: 'IT', name: 'italy', flag: 'italy', utf: 'it_IT.UTF8', locale_code: 'it_IT' };
            case 'pl-pl': // Polish
            case 'PL':
            case '616':
                return { id: '616', locale: 'pl-pl', code: 'PL', name: 'poland', flag: 'poland', utf: 'pl_PL.UTF8', locale_code: 'pl_PL' };
            case 'en-se': // English
            case 'EN':
            case '840':
                return { id: '840', locale: 'en-se', code: 'EN', name: 'usa', flag: 'usa', utf: 'en_US.UTF8', locale_code: 'en_US' };
            default: // Default- based on environment orgId
                switch (environment.orgId) {
                    case 'vefsalan':
                        return { id: '352', locale: 'is-is', code: 'IS', name: 'iceland', flag: 'iceland', utf: 'is_IS.UTF8', locale_code: 'is_IS' };
                    case 'varaosahaku':
                        return { id: '246', locale: 'fi-fi', code: 'FI', name: 'finland', flag: 'finland', utf: 'fi_FI.UTF8', locale_code: 'fi_FI' };
                    case 'autocirc':
                        return { id: '840', locale: 'en-se', code: 'EN', name: 'usa', flag: 'usa', utf: 'en_US.UTF8', locale_code: 'en_US' };
                    default:
                        return { id: '752', locale: 'sv-se', code: 'SE', name: 'sweden', flag: 'sweden', utf: 'sv_SE.UTF8', locale_code: 'sv_SE' };
                }
        }
    }

}
