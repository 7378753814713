// Angular
import { Injectable, inject, signal, computed } from '@angular/core';
// Constants
import { SAVE_PART_LS_KEY } from '@constants';
// Service
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root',
})
export class SavedListService {

    private commonService = inject(CommonService);

    /**
     * Signal to track saved items as a Set of part IDs.
     */
    public savedItemsSignal = signal<Set<number>>(this.getInitialSavedParts());

    /**
     * Signal to track the count of saved parts.
     * Computed dynamically from `savedItemsSignal`.
     */
    public savedPartsCountSignal = computed(() => this.savedItemsSignal().size);

    /**
     * Retrieves the initial saved parts from local storage and initializes the saved list.
     * @returns {Set<number>} A Set containing the part IDs stored in local storage.
     */
    private getInitialSavedParts(): Set<number> {
        const savedParts = this.commonService.getFromLocalStorage(SAVE_PART_LS_KEY);
        return new Set(savedParts ? savedParts : []);
    }

    /**
     * Updates the saved parts list by merging new data with existing data in the saved list.
     * Saves the updated state to local storage and updates the signal.
     * @param {Set<number>} newData - A Set of new part IDs to add to the saved list.
     */
    updateSavedParts(newData: Set<number>): void {
        const localStorageParts = new Set<number>(
            this.commonService.getFromLocalStorage(SAVE_PART_LS_KEY) || []
        );
        const updatedParts = new Set([...localStorageParts, ...newData]);

        this.savedItemsSignal.set(updatedParts); // Update signal
        this.commonService.setIntoLocalStorage(SAVE_PART_LS_KEY, Array.from(updatedParts)); // Save to local storage
    }

    /**
     * Adds a new part to the saved list.
     * Merges the new part with the existing saved parts in the signal and local storage.
     * @param {number} part - The ID of the part to add to the saved list.
     */
    addToSavedList(part: number): void {
        this.savedItemsSignal.update((parts) => {
            const localStorageParts = new Set<number>(
                this.commonService.getFromLocalStorage(SAVE_PART_LS_KEY) || []
            );
            const updatedParts = new Set([...localStorageParts, ...parts]);
            updatedParts.add(part); // Add the new part

            this.commonService.setIntoLocalStorage(SAVE_PART_LS_KEY, Array.from(updatedParts)); // Save to local storage

            return updatedParts; // Update signal with new data
        });
    }

    /**
     * Removes a part from the saved list.
     * Updates the signal and local storage by removing the specified part ID.
     * @param {number} partId - The ID of the part to remove from the saved list.
     */
    removeFromSavedList(partId: number): void {
        this.savedItemsSignal.update((parts) => {
            const localStorageParts = new Set<number>(
                this.commonService.getFromLocalStorage(SAVE_PART_LS_KEY) || []
            );
            const updatedParts = new Set([...localStorageParts, ...parts]);
            updatedParts.delete(partId); // Remove the part

            this.commonService.setIntoLocalStorage(SAVE_PART_LS_KEY, Array.from(updatedParts)); // Save to local storage

            return updatedParts; // Update signal with new data
        });
    }
}
