// Angular
import { Pipe, PipeTransform } from "@angular/core";
// Lodash
import { orderBy } from 'lodash';

/**
 * Returns sorted /ordered from Array
 */
@Pipe({
    name: "orderBy",
    standalone: false
})
export class OrderByPipe implements PipeTransform {

  /**
   * Order By pipe
   * @example orderBy: 'key': 'desc'
   * @param {*} array
   * @param {string} sortBy
   * @param {string} [order]
   * @returns {any[]}
   * @memberof OrderByPipe
   */
  transform(array: any, sortBy: string, order?: string): any[] {
    // Setting default ascending order
    const sortOrder: any = order ? order : 'asc';

    return orderBy(array, [sortBy], [sortOrder]);
  }
}