// Angular
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { environment } from '@environment';

// App Version
import packageInfo from 'package.json';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    standalone: false
})
export class AuthComponent implements OnInit, OnDestroy {
	
	// Public
	env = environment;
	today: Date = new Date();
	appVersion = packageInfo.version;

	/**
	 * Creates an instance of AuthComponent.
	 * @param {Document} document
	 * @memberof AuthComponent
	 */
	constructor(@Inject(DOCUMENT) private document: Document) { }

	ngOnInit(): void {
		this.document.body.classList.add('bg-body');
	}

	ngOnDestroy() {
		this.document.body.classList.remove('bg-body');
	}
}
