// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Pipes
import { MakeMePrettyPipe } from './make-me-pretty.pipe';
// Transloco
import { TranslocoService } from '@jsverse/transloco';


@Pipe({
    name: 'modelyear',
    standalone: false
})
export class ModelYearPipe implements PipeTransform {

  /**
   * Creates an instance of ModelYearPipe.
   * @param {TranslocoService} translocoService
   * @param {MakeMePrettyPipe} makeMePretty
   * @memberof ModelYearPipe
   */
  constructor(
    private translocoService: TranslocoService,
    private makeMePretty: MakeMePrettyPipe) {

  }

  /**
   * Transform
   * @param model_from: any
   * @param model_to: any
   */
  transform(model_from: any, model_to: any): any {
    if (null === model_from || null === model_to) return this.translocoService.translate('label_all');
    return this.makeMePretty.transform(model_from + '-' + model_to);
  }
}
