{
  "name": "bildelsbasen",
  "version": "5.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "serve": "node dist/too-perfect/bildelsbasen/server/server.mjs",
    "build:all": "npm run build && npm run build:varaosahaku && npm run build:autocirc && npm run build:vefsalan && npm run build:systemtest",
    "build:varaosahaku": "ng run too-perfect:build:production,varaosahaku",
    "serve:varaosahaku": "PORT=4001 node dist/too-perfect/varaosahaku/server/server.mjs",
    "build:autocirc": "ng run too-perfect:build:production,autocirc",
    "serve:autocirc": "PORT=4002 node dist/too-perfect/autocirc/server/server.mjs",
    "build:vefsalan": "ng run too-perfect:build:production,vefsalan",
    "serve:vefsalan": "PORT=4003 node dist/too-perfect/vefsalan/server/server.mjs",
    "build:systemtest": "ng run too-perfect:build:production,systemtest",
    "serve:systemtest": "PORT=4002 node dist/too-perfect/systemtest/server/server.mjs",
    "sentry-1": "cross-env CURRENT_VERSION=\"$npm_package_version\" npm run sentry-finalize",
    "sentry-finalize": "cross-env-shell \"sentry-cli releases new too-perfect-v$CURRENT_VERSION --finalize\"",
    "sentry-2": "cross-env CURRENT_VERSION=\"$npm_package_version\" npm run sentry-releases",
    "sentry-releases": "cross-env-shell \"sentry-cli releases set-commits too-perfect-v$CURRENT_VERSION --auto --ignore-missing\"",
    "sentry": "npm run sentry-1 && npm run sentry-2",
    "git-version": "git push",
    "release": "npm run sentry && npm run build && npm run git-version"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.1.3",
    "@angular/cdk": "^19.1.1",
    "@angular/common": "19.1.3",
    "@angular/compiler": "19.1.3",
    "@angular/core": "19.1.3",
    "@angular/forms": "19.1.3",
    "@angular/material": "19.1.1",
    "@angular/platform-browser": "19.1.3",
    "@angular/platform-browser-dynamic": "19.1.3",
    "@angular/platform-server": "19.1.3",
    "@angular/router": "19.1.3",
    "@angular/ssr": "^19.1.4",
    "@fortawesome/fontawesome-free": "6.7.2",
    "@jsverse/transloco": "^7.5.1",
    "@jsverse/transloco-persist-lang": "^7.0.2",
    "@ng-bootstrap/ng-bootstrap": "18.0.0",
    "@ngrx/effects": "^19.0.0",
    "@ngrx/entity": "^19.0.0",
    "@ngrx/operators": "^19.0.0",
    "@ngrx/router-store": "^19.0.0",
    "@ngrx/store": "^19.0.0",
    "@ngx-loading-bar/core": "^7.0.0",
    "@ngx-loading-bar/router": "^7.0.0",
    "@popperjs/core": "2.11.8",
    "@sentry/angular": "^8.51.0",
    "@types/google.analytics": "^0.0.46",
    "@types/lodash": "^4.17.14",
    "@uppy/angular": "^0.7.0",
    "@uppy/core": "^4.4.1",
    "@uppy/dashboard": "^4.3.1",
    "@uppy/drag-drop": "^4.1.1",
    "@uppy/progress-bar": "^4.2.1",
    "@uppy/status-bar": "^4.1.1",
    "animate.css": "4.1.1",
    "bootstrap": "5.3.3",
    "bootstrap-icons": "1.11.3",
    "clipboard": "2.0.11",
    "cross-env": "^7.0.3",
    "express": "^4.21.2",
    "lodash": "^4.17.21",
    "material-design-icons": "^3.0.1",
    "ng-gallery": "^12.0.0",
    "ng-inline-svg-2": "15.0.1",
    "ng-recaptcha": "^13.2.1",
    "ngx-clipboard": "16.0.0",
    "ngx-cookie-service-ssr": "^19.0.0",
    "ngx-device-detector": "^9.0.0",
    "ngx-mat-select-search": "^7.0.10",
    "rxjs": "7.8.1",
    "tslib": "2.8.1",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/core": "^19.1.4",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/build": "^19.1.4",
    "@angular/cli": "19.1.4",
    "@angular/compiler-cli": "19.1.3",
    "@angular/localize": "^19.1.3",
    "@types/bootstrap": "5.2.10",
    "@types/express": "^5.0.0",
    "@types/gtag.js": "^0.0.20",
    "@types/jasmine": "5.1.5",
    "@types/node": "22.10.10",
    "@types/object-path": "0.11.4",
    "@types/sass-loader": "8.0.9",
    "@typescript-eslint/eslint-plugin": "^8.21.0",
    "@typescript-eslint/parser": "^8.21.0",
    "del": "8.0.0",
    "eslint": "^9.18.0",
    "prettier": "^3.4.2",
    "typescript": "5.7.3"
  },
  "resolutions": {
    "autoprefixer": "10.4.5"
  }
}
